import {Button, Form, Modal} from "semantic-ui-react";

function App() {
  return (<Modal open={true} size="mini">
    <Modal.Header>Вход</Modal.Header>
    <Modal.Content>
      <Form>
        <Form.Field>
          <label>Адрес эл. почты</label>
          <input placeholder="mailbox@example.org"/>
        </Form.Field>
        <p>На указанный адрес будет отправлена ссылка для входа в систему.</p>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button>Отменить</Button>
      <Button primary>Войти</Button>
    </Modal.Actions>
  </Modal>);
}

export default App;
